@import "https://fonts.googleapis.com/css2?family=Inter&family=Space+Mono:wght@400;700&display=swap";
body, body * {
  color: currentColor;
  line-height: 1.45;
}

html {
  color: #fff;
  font-family: Inter, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: -.04em;
  font-family: Space Mono, monospace;
  font-weight: 700;
  line-height: 1;
}

h2 {
  font-size: clamp(64px, 11.3889vw, 164px);
}

h3 {
  font-size: clamp(32px, 4.44444vw, 64px);
}

p + p {
  margin-top: 5px;
}

a {
  color: currentColor;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.editorial-content h3 + p, .editorial-content p + p {
  margin-top: 22px;
}

.editorial-content ul, .editorial-content li + li {
  margin-top: 11px;
}

.editorial-content ul {
  margin-left: 22px;
  list-style-type: disc;
}

* {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

body {
  width: 100vw;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hide, .iub__us-widget.iub__us-widget {
  display: none;
}

.main-header {
  z-index: 1;
  padding: 22px clamp(20px, 1.12676vw + 15.7746px, 32px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 1023px) {
  .main-header {
    background-color: #1e1e1e;
  }
}

@media only screen and (min-width: 1024px) {
  .main-header .content {
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
}

.main-header .logo {
  opacity: 0;
  width: 135px;
}

@media only screen and (max-width: 1023px) {
  .main-header .logo {
    display: none;
  }
}

.main-header, .main-header .logo {
  transition: all .4s ease-in-out;
}

.main-header.active {
  background-color: #1e1e1e;
}

.main-header.active .logo {
  opacity: 1;
}

.main-header nav {
  font-family: Space Mono, monospace;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .main-header nav {
    letter-spacing: -.5px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1023px) {
  .main-header nav {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }
}

@media only screen and (min-width: 1024px) {
  .main-header nav a + a {
    margin-left: clamp(32px, 3.56808vw + 18.6197px, 70px);
  }
}

.main-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-footer .content {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 70px 20px clamp(20px, 1.12676vw + 15.7746px, 32px);
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (min-width: 1024px) {
  .main-footer .content {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1366px) {
  .main-footer .content {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.main-footer .content .iubenda-nostyle {
  width: auto !important;
  height: auto !important;
  text-decoration: underline !important;
}

.main-footer .content .iubenda-nostyle:first-child {
  margin-right: 10px;
}

@media only screen and (min-width: 1366px) {
  .main-footer .content p + p {
    margin-top: 0;
  }
}

.services-list {
  position: relative;
}

.services-list .service-item {
  cursor: pointer;
  border-bottom: 2px solid #fff;
  padding: 26px 0;
}

.services-list .service-item .service-content {
  transition: height .4s ease-in-out;
}

.services-list .service-item .service-content > :first-child {
  margin-top: 26px;
}

.services-list .service-item h3 {
  letter-spacing: 0;
  font-size: clamp(32px, 3.33333vw, 48px);
}

.services-list .service-item.closed .service-content {
  visibility: hidden;
  width: 100%;
  position: absolute;
  bottom: 100vh;
  left: 100vw;
}

.services-list .service-item.open .service-content {
  height: 0;
  overflow: hidden;
}

.page-section {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.page-section .content {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 124px clamp(20px, 1.12676vw + 15.7746px, 32px);
}

@media only screen and (min-width: 1366px) {
  .page-section .content {
    padding: 134px 95px 134px clamp(20px, 1.12676vw + 15.7746px, 32px);
  }
}

@media only screen and (min-width: 1024px) {
  .page-section .content-section {
    align-items: center;
    display: flex;
  }
}

#home {
  background-color: #0000;
  background-image: url("bg-home.27bf7aca.png"), linear-gradient(to right, #e0f, #6048ff);
  background-position: center;
  background-repeat: repeat-x, no-repeat;
  background-size: auto 100vh, cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#home .logo {
  color: #0000;
  text-align: center;
  width: 100%;
  padding: 0 clamp(20px, 1.12676vw + 15.7746px, 32px);
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  #home .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#home .logo img {
  width: clamp(258px, 25.5399vw + 162.225px, 530px);
}

@media only screen and (min-width: 1024px) {
  #home .logo img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#home .logo .tagline {
  font-family: Space Mono, monospace;
  font-size: clamp(24px, 4.23611vw, 61px);
}

@media only screen and (max-width: 1023px) {
  #home .logo .tagline {
    color: #fff;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  #home .logo .tagline {
    letter-spacing: -.04em;
    -webkit-text-stroke: 1px #fff;
    text-stroke: 1px #fff;
    z-index: 1;
    font-family: Space Mono, monospace;
    font-weight: 700;
    line-height: 1;
    position: fixed;
    bottom: 46px;
    left: 0;
    right: 0;
  }
}

#about {
  background-color: #0000;
  background-image: url("bg-about.4d8d4e18.jpg"), linear-gradient(to right, #1fc904, #308003);
  background-position: top, center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#about .content-section--1 {
  margin-top: 109px;
}

#about .content-section--2 picture {
  max-width: 414px;
}

#about .content-section--3 picture {
  max-width: 426px;
}

#about .content-section--4 {
  margin-top: 93px;
}

#about .content-section--5 {
  margin-top: 80px;
}

#about .content-piece h3 {
  font-size: clamp(32px, 4.44444vw, 64px);
}

#about .content-piece picture, #about .content-piece img {
  display: block;
}

#about .content-piece img {
  width: 100%;
}

#about .technologies {
  margin-top: 14px;
}

#about .technologies-list {
  white-space: nowrap;
  flex-wrap: wrap;
  display: flex;
}

#about .technologies li {
  align-items: center;
  margin: 8px 0;
  font-family: Space Mono, monospace;
  font-size: clamp(24px, 4.44444vw, 64px);
  display: flex;
}

#about .technologies li:not(:last-child):after {
  content: "|";
  padding: 0 clamp(16px, 1.52778vw, 22px);
}

#about .clients {
  margin-top: 35px;
}

#about .clients-list {
  white-space: nowrap;
  flex-wrap: wrap;
  align-items: center;
  margin: -20px;
  display: flex;
}

#about .clients li {
  margin: 20px;
}

@media only screen and (min-width: 1024px) {
  #about .content-section {
    align-items: center;
    display: flex;
  }

  #about .content-section--1 {
    margin-top: 66px;
  }

  #about .content-section--2, #about .content-section--3 {
    margin-top: 155px;
  }

  #about .content-section--3 .content-piece--with-text {
    flex: 0 0 46%;
  }

  #about .content-section--4 {
    margin-top: 166px;
  }

  #about .content-section--5 {
    margin-top: 160px;
  }

  #about .content-section .content-piece--with-picture {
    flex: auto;
  }

  #about .content-section .content-piece--with-text {
    flex: 0 0 53.4%;
  }

  #about .content-section .content-piece--left {
    order: 1;
  }

  #about .content-section .content-piece--right {
    order: 2;
  }

  #about .content-section .content-piece picture {
    width: 100%;
    margin: 0 auto;
  }

  #about .content-section .technologies {
    margin-top: 11px;
  }

  #about .content-section .technologies li {
    margin: 11px 0;
  }

  #about .content-section .technologies--list + #about .content-section .technologies--list {
    margin-top: 5px;
  }

  #about .content-section .clients {
    margin-top: 70px;
  }

  #about .content-section .clients-list {
    margin: -30px;
  }

  #about .content-section .clients li {
    margin: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  #about .content-section--2 picture {
    width: 80%;
    margin: 107px auto 45px 90px;
  }

  #about .content-section--3 picture {
    width: 77.3%;
    margin: 140px 114px 57px auto;
  }
}

@media only screen and (max-width: 767px) {
  #about .content-section--2 picture {
    width: 80%;
    margin: 107px auto 45px 14px;
  }

  #about .content-section--3 picture {
    width: 77.3%;
    margin: 140px 26px 57px auto;
  }
}

#services {
  background-color: #0000;
  background-image: url("bg-services.6295581c.jpg"), linear-gradient(to right, #ea6428, #ea57b2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#services .content-section picture {
  max-width: 414px;
}

@media only screen and (max-width: 1023px) {
  #services .content-section picture {
    width: 80%;
    margin: 107px 90px 45px auto;
  }
}

@media only screen and (max-width: 767px) {
  #services .content-section picture {
    width: 80%;
    margin: 107px 14px 45px auto;
  }
}

#services .content-piece picture, #services .content-piece img {
  display: block;
}

#services .content-piece img {
  width: 100%;
}

@media only screen and (max-width: 1023px) {
  #services h2 + p {
    margin: 22px 0 36px;
  }
}

@media only screen and (min-width: 1024px) {
  #services .content-section {
    align-items: center;
    display: flex;
  }

  #services .content-section .content-piece--with-picture {
    flex: auto;
  }

  #services .content-section .content-piece--with-text {
    flex: 0 0 54.4%;
  }

  #services .content-section .content-piece--left {
    order: 1;
  }

  #services .content-section .content-piece--right {
    order: 2;
  }

  #services .content-section .content-piece picture {
    width: 100%;
    margin: 0 0 0 auto;
  }
}

#aws {
  background-color: #0000;
  background-image: url("bg-aws.cc380120.jpg"), linear-gradient(to right, #e5bf1a, #86843a);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#aws .content-section {
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 1023px) {
  #aws .content-section h2 + p {
    margin: 22px 0 36px;
  }
}

#aws .content-section p + p {
  margin-top: 22px;
}

@media only screen and (min-width: 1024px) {
  #aws .content-section .content-piece {
    flex: 0 0 54.4%;
  }

  #aws .content-section .content-piece--with-text {
    flex: 0 0 36.8%;
  }
}

#directus {
  background-color: #0000;
  background-image: url("bg-home.27bf7aca.png"), linear-gradient(to right, #e0f, #6048ff);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#directus .content-section {
  justify-content: space-between;
  align-items: flex-start;
}

#directus .content-section .content-piece--right {
  margin: 22px 0 36px;
}

#directus .content-section .content-piece--right p + p {
  margin-top: 22px;
}

@media only screen and (min-width: 1024px) {
  #directus .content-section .content-piece {
    flex: 0 0 54.4%;
  }

  #directus .content-section .content-piece--left {
    order: 1;
  }

  #directus .content-section .content-piece--right {
    order: 2;
  }

  #directus .content-section .content-piece--with-text {
    flex: 0 0 36.8%;
  }
}

#contact {
  background-color: #0000;
  background-image: url("bg-contact.88469b72.jpg"), linear-gradient(to right, #007aff, #4700ff);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

#contact h2 {
  margin-bottom: 36px;
  font-size: clamp(64px, 10.4167vw, 150px);
}

#contact a {
  text-decoration: underline;
}

/*# sourceMappingURL=index.4f9f6fee.css.map */
