#directus {
  background: url('/src/images/bg-home.png'), linear-gradient(to right, map-get($theme-colors, 'purple-light'), map-get($theme-colors, 'purple-dark'));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .content-section {
    align-items: flex-start;
    justify-content: space-between;

    .content-piece--right {
      margin: 22px 0 36px;

      p + p {
        margin-top: 22px;
      }
    }

    @include breakpoint(desktop) {
      .content-piece {
        flex: 0 0 54.4%;

        &--left {
          order: 1;
        }

        &--right {
          order: 2;
        }
      }
      .content-piece--with-text {
        flex: 0 0 36.8%;
      }
    }
  }
}
