@import url('https://fonts.googleapis.com/css2?family=Inter&family=Space+Mono:wght@400;700&display=swap');

body, body * {
  color: currentColor;
  line-height: 1.45;
}

html {
  color: map-get($colors, 'white');
  font-family: 'Inter', Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
}

h1, h2, h3, h4, h5, h6 {
  @include as-h;
}

h2 {
  font-size: max-clamp(64px, 164px);
}

h3 {
  font-size: max-clamp(32px, 64px);
}

p + p {
  margin-top: 5px;
}

a {
  color: currentColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.editorial-content {
  h3 + p,
   p + p {
    margin-top: 22px;
  }

  ul,
  li + li {
    margin-top: 11px;
  }

  ul {
    list-style-type: disc;
    margin-left: 22px;
  }
}
