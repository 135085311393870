#about {
  background: url('/src/images/bg-about.jpg'), linear-gradient(to right, map-get($theme-colors, 'green-light'), map-get($theme-colors, 'green-dark'));
  background-position: top center, center;
  background-repeat: no-repeat;
  background-size: cover;

  .content-section {

    &--1 {
      margin-top: 109px;
    }

    &--2 picture {
      max-width: 414px;
    }

    &--3 picture {
      max-width: 426px;
    }

    &--4 {
      margin-top: 93px;
    }

    &--5 {
      margin-top: 80px;
    }
  }

  .content-piece {

    h3 {
      font-size: max-clamp(32px, 64px);
    }

    picture, img {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  .technologies {
    margin-top: 14px;

    &-list {
      display: flex;
      flex-wrap: wrap;
      white-space: nowrap;
    }

    li {
      display: flex;
      align-items: center;
      font-family: 'Space Mono', monospace;
      font-size: max-clamp(24px, 64px);
      margin: 8px 0;

      &:not(:last-child):after {
        content: '|';
        padding: 0 max-clamp(16px, 22px);
      }
    }
  }

  .clients {
    margin-top: 35px;

    &-list {
      display: flex;
      flex-wrap: wrap;
      white-space: nowrap;
      align-items: center;
      margin: -20px;
    }

    li {
      margin: 20px;
    }
  }

  @include breakpoint(desktop) {
    .content-section {
      display: flex;
      align-items: center;

      &--1 {
        margin-top: 66px;
      }

      &--2, &--3 {
        margin-top: 155px;
      }

      &--3 .content-piece--with-text {
        flex: 0 0 46%;
      }

      &--4 {
        margin-top: 166px;
      }

      &--5 {
        margin-top: 160px;
      }

      .content-piece {

        &--with-picture {
          flex: 1 1 auto;
        }

        &--with-text {
          flex: 0 0 53.4%;
        }

        &--left {
          order: 1;
        }

        &--right {
          order: 2;
        }

        picture {
          margin: 0 auto;
          width: 100%;
        }
      }

      .technologies {
        margin-top: 11px;

        li {
          margin: 11px 0;
        }

        &--list + &--list {
          margin-top: 5px;
        }
      }

      .clients {
        margin-top: 70px;

        &-list {
          margin: -30px;
        }

        li {
          margin: 30px;
        }
      }
    }
  }

  @include breakpoint(desktop, max) {
    .content-section {

      &--2 picture {
        margin: 107px auto 45px 90px;
        width: 80%;
      }

      &--3 picture {
        margin: 140px 114px 57px auto;
        width: 77.3%
      }
    }
  }

  @include breakpoint(tablet, max) {
    .content-section {

      &--2 picture {
        margin: 107px auto 45px 14px;
        width: 80%;
      }

      &--3 picture {
        margin: 140px 26px 57px auto;
        width: 77.3%
      }
    }
  }
}
