.main-footer {
  @include position (null, 0, 0, 0, absolute);

  .content {
    @include margin-side-auto;
    font-size: 14px;
    font-weight: 500;
    max-width: map-get($dimensions, 'content-max-width');
    padding: 20px gutter-h-clamp();
    padding-right: 70px;
    @include breakpoint(desktop) {
      font-size: 16px;
    }
    @include breakpoint(desktop-wide) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .iubenda-nostyle {
      @include size(auto !important);
      text-decoration: underline !important;

      &:first-child {
        margin-right: 10px;
      }
    }

    p + p {
      @include breakpoint(desktop-wide) {
        margin-top: 0;
      }
    }
  }
}
