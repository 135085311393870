#home {
  background: url('/src/images/bg-home.png'), linear-gradient(to right, map-get($theme-colors, 'purple-light'), map-get($theme-colors, 'purple-dark'));
  background-position: center;
  background-repeat: repeat-x, no-repeat;
  background-size: auto 100vh, cover;

  .logo {
    color: transparent;
    font-size: 0;
    padding: 0 gutter-h-clamp();
    text-align: center;
    width: 100%;
    @include breakpoint(desktop, max) {
      @include absolute-center;
    }

    img {
      width: average-clamp(258px, 530px);
      @include breakpoint(desktop) {
        @include absolute-center;
      }
    }

    .tagline {
      font-family: 'Space Mono', monospace;
      font-size: max-clamp(24px, 61px);
      @include breakpoint(desktop, max) {
        color: map-get($colors, 'white');
        margin-top: 10px;
      }
      @include breakpoint(desktop) {
        @include position(null, 0, 46px, 0, fixed);
        @include as-h;
        -webkit-text-stroke: 1px map-get($colors, 'white');
        text-stroke: 1px map-get($colors, 'white');
        z-index: 1;
      }
    }
  }
}
