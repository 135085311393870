* {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

body {
  @include size(100vw, 100%);
  position: relative;
  overflow-x: hidden;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hide {
  display: none;
}

// hide iubenda us footer widget, leave only the signature button
.iub__us-widget.iub__us-widget {
  display: none;
}