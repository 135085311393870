.main-header {
  @include position(0, 0, null, 0, fixed);
  padding: 22px gutter-h-clamp();
  z-index: 1;
  @include breakpoint(desktop, max) {
    background-color: map-get($colors, 'black');
  }

  .content {
    @include breakpoint(desktop) {
      @include margin-side-auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-width: map-get($dimensions, 'content-max-width');
    }
  }

  .logo {
    width: 135px;
    opacity: 0;
    @include breakpoint(desktop, max) {
      display: none;
    };
  }

  &, & .logo {
    transition: all 0.4s ease-in-out;
  }

  &.active {
    background-color: map-get($colors, 'black');

    .logo {
      opacity: 1;
    }
  }

  nav {
    font-family: 'Space Mono', monospace;
    font-weight: 700;
    @include breakpoint(tablet, max) {
      font-size: 14px;
      letter-spacing: -0.5px;
    }
    @include breakpoint(desktop, max) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    @include breakpoint(desktop) {
      a + a {
        margin-left: average-clamp(map-get($dimensions, 'gutter-h'), 70px);
      }
    }
  }
}
