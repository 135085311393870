#aws {
  background: url('/src/images/bg-aws.jpg'), linear-gradient(to right, map-get($theme-colors, 'yellow-light'), map-get($theme-colors, 'yellow-dark'));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .content-section {
    align-items: flex-start;
    justify-content: space-between;

    @include breakpoint(desktop, max) {
      h2 + p {
        margin: 22px 0 36px;
      }
    }

    p + p {
      margin-top: 22px;
    }

    @include breakpoint(desktop) {
      .content-piece {
        flex: 0 0 54.4%;
      }
      .content-piece--with-text {
        flex: 0 0 36.8%;
      }
    }
  }
}
