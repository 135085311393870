@import './partials/_variables.scss';
@import './partials/_mixins.scss';
@import './partials/_typography.scss';
@import './partials/_base.scss';
@import './partials/components/_header.scss';
@import './partials/components/_footer.scss';
@import './partials/components/_services-list.scss';
@import './partials/sections/_base.scss';
@import './partials/sections/_home.scss';
@import './partials/sections/_about.scss';
@import './partials/sections/_services.scss';
@import './partials/sections/_aws.scss';
@import './partials/sections/_directus.scss';
@import './partials/sections/_contact.scss';
