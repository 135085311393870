.services-list {
  position: relative;

  .service-item {
    border-bottom: 2px solid map-get($colors, 'white');
    cursor: pointer;
    padding: 26px 0;

    .service-content {
      transition: height 0.4s ease-in-out;

      > *:first-child {
        margin-top: 26px;
      }
    }

    h3 {
      font-size: max-clamp(32px, 48px);
      letter-spacing: 0;
    }

    &.closed .service-content {
      @include position(null, null, 100vh, 100vw);
      visibility: hidden;
      width: 100%;
    }

    &.open .service-content {
      height: 0;
      overflow: hidden;
    }
  }
}
