#contact {
  background: url('/src/images/bg-contact.jpg'), linear-gradient(to right, map-get($theme-colors, 'blue-light'), map-get($theme-colors, 'blue-dark'));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    font-size: max-clamp(64px, 150px);
    margin-bottom: 36px;
  }

  a {
    text-decoration: underline;
  }
}
