#services {
  background: url('/src/images/bg-services.jpg'), linear-gradient(to right, map-get($theme-colors, 'red-light'), map-get($theme-colors, 'red-dark'));
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .content-section picture {
    max-width: 414px;

    @include breakpoint(desktop, max) {
      margin: 107px 90px 45px auto;
      width: 80%;
    }

    @include breakpoint(tablet, max) {
      margin: 107px 14px 45px auto;
      width: 80%;
    }
  }

  .content-piece {

    picture, img {
      display: block;
    }

    img {
      width: 100%;
    }
  }

  @include breakpoint(desktop, max) {
    h2 + p {
      margin: 22px 0 36px;
    }
  }

  @include breakpoint(desktop) {
    .content-section {
      display: flex;
      align-items: center;

      .content-piece {

        &--with-picture {
          flex: 1 1 auto;
        }

        &--with-text {
          flex: 0 0 54.4%;
        }

        &--left {
          order: 1;
        }

        &--right {
          order: 2;
        }

        picture {
          margin: 0 0 0 auto;
          width: 100%;
        }
      }
    }
  }
}
