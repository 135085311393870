.page-section {
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  position: relative;

  .content {
    @include margin-side-auto;
    max-width: map-get($dimensions, 'content-max-width');
    padding: 124px gutter-h-clamp();
    @include breakpoint(desktop-wide) {
      padding: 134px 95px;
      padding-left: gutter-h-clamp();
    }
  }

  @include breakpoint(desktop) {
    .content-section {
      display: flex;
      align-items: center;
    }
  }
}
