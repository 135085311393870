@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin breakpoint($width, $type: min) {
  @if map-has-key($breakpoints, $width) {
    $width: map-get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin margin-side-auto {
  margin: {
    right: auto;
    left: auto;
  }
}

@mixin position($top, $right, $bottom: $top, $left: $right, $position: absolute) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin pseudo-element($content: '', $display: block) {
  content: $content;
  display: $display;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin as-h {
  font-family: 'Space Mono', monospace;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.04em;
}

@function min-clamp($min, $max) {
  @return clamp($min, calc($min * 100 / map-get($dimensions, 'content-min-width')) * 1vw, $max);
}

@function average-clamp($min, $max) {
  @return clamp($min, calc($min + (($max - $min) / 1px) * ((100vw - map-get($dimensions, 'content-min-width')) / ((map-get($dimensions, 'content-max-width') - map-get($dimensions, 'content-min-width')) / 1px))), $max);
}

@function max-clamp($min, $max) {
  @return clamp($min, calc($max * 100 / map-get($dimensions, 'content-max-width')) * 1vw, $max);
}

@function gutter-h-clamp() {
  @return average-clamp(map-get($dimensions, 'gutter-h-mobile'), map-get($dimensions, 'gutter-h'));
}
